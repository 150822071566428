<template>
  <section>
    <b-card>
      <b-row class="match-height">
        <!--        <b-col cols="12">
          <product-section :section-title="$t('parameters.products.types_of_goods')" :values="typeOfGoods" :item-index="itemIndex" url-suffix="type-of-good" />
        </b-col>-->
        <b-col cols="12">
          <b-overlay :show="loading">
            <product-packing-section v-if="!loading" :item-index="itemIndex" :section-title="$t('parameters.products.packings')" :values="packings" url-suffix="packing" />
          </b-overlay>
        </b-col>
      </b-row>

      <!--      <b-row class="match-height">
        <b-col cols="12">
          <product-section :section-title="$t('parameters.products.typologies')" :values="typologies" :item-index="itemIndex" url-suffix="typology" />
        </b-col>
      </b-row>-->
    </b-card>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
// import ProductSection from '@/views/parameters/tab/products/ProductSection.vue'
import ProductPackingSection from '@/views/parameters/tab/products/ProductPackingSection.vue'
import { fetchProductConfigurationRequest } from '@/request/globalApi/requests/productConfigurationRequests'

export default {
  name: 'Products',

  components: {
    // ProductSection,
    ProductPackingSection,
  },

  mixins: [heightTransition],
  data() {
    return {
      itemIndex: 2,
      typeOfGoods: [],
      typologies: [],
      packings: [],
      loading: true,
    }
  },
  mounted() {
    this.fetchProductConfiguration()
  },
  methods: {
    fetchProductConfiguration() {
      fetchProductConfigurationRequest().then(response => {
        this.typeOfGoods = response.data.typeOfGoods.map((typeOfGood, index) => ({
          id: typeOfGood.id,
          index,
          name: typeOfGood.name,
          initialName: JSON.parse(JSON.stringify(typeOfGood.name)),
        }))

        this.typologies = response.data.typologies.map((typology, index) => ({
          id: typology.id,
          index,
          name: typology.name,
          initialName: JSON.parse(JSON.stringify(typology.name)),
        }))

        this.packings = response.data.packings.map((packing, index) => ({
          ...packing,
          index,
          initialType: packing.type,
          initialName: JSON.parse(JSON.stringify(packing.name)),
        }))
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
