<template>
  <!--  <b-card :key="componentKey">-->
  <b-card>
    <h4 class="mb-1">
      {{ sectionTitle }}
    </h4>
    <input-lang :active-language="currentLanguage" @switch-lang="setCurrentLanguage" />
    <b-form ref="form" @submit.prevent="newLine">
      <div v-for="value in values" :id="value.index" :key="value.index" class="pb-1">
        <b-input-group>
          <b-input-group-prepend>
            <validation-provider #default="{ errors }" rules="required">
              <b-form-group>
                <b-form-select id="ProductPackingType" v-model="value.type" :options="ProductPackingType" @change="dirtyValueLine(value)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-input-group-prepend>
          <b-form-input
            v-model="value.name[currentLanguage]"
            :placeholder="value.name[currentLanguage] ? value.name[currentLanguage] : $t('translation.undefined')"
            type="text"
            @keyup="dirtyValueLine(value)"
          />
          <b-input-group-append>
            <b-button v-if="isItemLoading(value.index)" v-ripple.400 :disabled="true" size="md" variant="outline-primary">
              <b-spinner :label="$t('common.form.loading')" small type="grow" />
            </b-button>
            <b-button
              v-else-if="$can('OPERATOR_PARAMETER_EDIT') && isItemDirty(value.index) && value.name[defaultLanguage] && value.name[defaultLanguage] !== ''"
              v-ripple.400
              size="md"
              variant="outline-primary"
              @click="validChanges(value.index)"
            >
              <font-awesome-icon v-b-tooltip.hover.bottom.v-danger :title="$t('action.add')" class="text-success" icon="save" />
            </b-button>
            <b-button v-else v-ripple.400 :disabled="true" size="md" variant="outline-primary">
              <font-awesome-icon v-b-tooltip.hover.bottom.v-danger :title="$t('action.add')" class="text-grey" icon="check-circle" />
            </b-button>
            <b-button v-if="isItemLoadingForDelete(value.index)" v-ripple.400 :disabled="true" size="md" variant="outline-primary">
              <b-spinner :label="$t('common.form.loading')" small type="grow" />
            </b-button>
            <b-button v-else-if="$can('OPERATOR_PARAMETER_DELETE')" v-ripple.400 size="md" variant="outline-primary" @click="deleteItem(value.index)">
              <font-awesome-icon v-b-tooltip.hover.bottom.v-danger :title="$t('action.delete')" class="text-danger" icon="trash" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small
          v-if="!value.name[defaultLanguage]"
          class="text-danger"
        >{{ $t('common.error.default_language_required') }} ({{ defaultLanguage }})</small>
      </div>

      <div v-if="$can('OPERATOR_PARAMETER_EDIT')" :id="newItemLine.index" :key="newItemLine.index">
        <b-input-group>
          <b-input-group-prepend>
            <validation-provider #default="{ errors }" rules="required">
              <b-form-group>
                <b-form-select id="ProductPackingType" v-model="newItemLine.type" :options="ProductPackingType">
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-input-group-prepend>
          <b-form-input
            v-model="newItemLine.name[currentLanguage]"
            :placeholder="newItemLine.name[currentLanguage] ? newItemLine.name[currentLanguage] : $t('translation.undefined')"
            type="text"
            @keyup="dirtyNewItemLine"
          />
          <b-input-group-append>
            <b-button
              v-if="!isItemLoading(newItemLine.index)"
              v-ripple.400
              :disabled="!isItemDirty(newItemLine.index) || !newItemLine.name[defaultLanguage]"
              @click="validChanges(newItemLine.index)"
            >
              <feather-icon class="mr-25" icon="PlusIcon" />
            </b-button>
            <b-button v-else v-ripple.400 :disabled="true">
              <b-spinner :label="$t('common.form.loading')" small type="grow" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small v-if="isItemDirty(newItemLine.index) && (!newItemLine.name[defaultLanguage])" class="text-danger">
          {{ $t('common.error.default_language_required') }} ({{ defaultLanguage }})</small>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  deleteProductConfigurationRequest,
  patchProductConfigurationRequest,
  postProductConfigurationRequest,
} from '@/request/globalApi/requests/productConfigurationRequests'
import InputLang from '@/views/product/tab/InputLang.vue'

export default {
  name: 'ProductPackingSection',

  components: {
    InputLang,
  },

  props: {
    sectionTitle: {
      type: String,
      default: '',
    },
    values: {
      type: Array,
      default: () => [],
    },
    urlSuffix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      componentKey: 0,
      currentLanguage: this.userLang,
      itemIndex: 2,
      newItemLine: { name: {} },
      newItemLinePristine: true,
      defaultLanguage: localStorage.getItem('appLang'),
      dirtyValuesIndexes: [],
      loadingIndexes: [],
      loadingDeleteIndexes: [],
      translationsKeys: [],
    }
  },
  computed: {
    // TODO: enum-select
    ...mapState('enum', ['ProductPackingType']),
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  mounted() {
    this.setCurrentLanguage(this.userLang)
    this.newLine()
    this.fetchEnum2('ProductPackingType')
  },
  methods: {
    ...mapActions('enum', ['fetchEnum2']),
    setCurrentLanguage(val) {
      this.currentLanguage = val
    },
    isItemDirty(index) {
      return this.dirtyValuesIndexes.indexOf(index) > -1
    },
    addDirtyStatus(index) {
      if (!this.isItemDirty(index)) {
        this.dirtyValuesIndexes.push(index)
      }
    },
    removeDirtyStatus(index) {
      const itemDirtyIndex = this.dirtyValuesIndexes.indexOf(index)
      if (itemDirtyIndex > -1) {
        this.dirtyValuesIndexes.splice(itemDirtyIndex, 1)
      }
    },
    isItemLoading(index) {
      return this.loadingIndexes.indexOf(index) > -1
    },
    removeLoadingStatus(index) {
      const itemLoadingIndex = this.loadingIndexes.indexOf(index)
      if (itemLoadingIndex > -1) {
        this.loadingIndexes.splice(itemLoadingIndex, 1)
      }
    },
    isItemLoadingForDelete(index) {
      return this.loadingDeleteIndexes.indexOf(index) > -1
    },
    removeLoadingDeleteStatus(index) {
      const itemLoadingDeleteIndex = this.loadingDeleteIndexes.indexOf(index)
      if (itemLoadingDeleteIndex > -1) {
        this.loadingDeleteIndexes.splice(itemLoadingDeleteIndex, 1)
      }
    },
    newLine() {
      const translationsArray = []
      this.values.forEach(value => Object.keys(value.name).forEach(locale => {
        if (this.translationsKeys.indexOf(locale) < 0) {
          this.translationsKeys.push(locale)
        }
      }))
      this.translationsKeys = this.values.map(value => Object.keys(value.name))
      const name = Object.fromEntries(translationsArray)
      this.translationsKeys.forEach(locale => {
        const translationData = [locale, '']
        translationsArray.push(translationData)
      })
      const sectionTitleSnake = this.sectionTitle
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_')
      this.newItemLine = {
        id: null,
        index: sectionTitleSnake + (this.itemIndex += this.itemIndex),
        type: 'numbers',
        initialType: 'numbers',
        name,
        initialName: name,
      }
      this.itemIndex += 1
    },
    dirtyNewItemLine() {
      const dirtyLocaleName = Object.values(this.newItemLine.name).filter(name => name !== '')
      if (dirtyLocaleName.length > 0) {
        this.addDirtyStatus(this.newItemLine.index)
      } else {
        this.removeDirtyStatus(this.newItemLine.index)
      }
    },
    dirtyValueLine(value) {
      this.removeDirtyStatus(value.index)
      Object.keys(value.name).forEach(locale => {
        if (
          value.name[locale] !== value.initialName[locale]
          || value.type !== value.initialType
        ) {
          this.addDirtyStatus(value.index)
        }
      })
    },
    alertDeleteSuccess() {
      this.alert(this.$t('alert.delete.success'))
    },
    deleteItem(index) {
      const item = this.values.filter(valueItem => valueItem.index === index)[0]
      deleteProductConfigurationRequest(this.urlSuffix, item).then(response => {
        if (response) {
          this.values.splice(this.values.indexOf(item), 1)
          this.removeLoadingDeleteStatus()
        }
      })
    },
    validChanges(index) {
      const item = this.newItemLine.index === index ? this.newItemLine : this.values.find(valueItem => valueItem.index === index)
      const isNewLine = item === this.newItemLine
      if (!this.isItemLoading(item.index)) {
        this.loadingIndexes.push(item.index)
      }
      const data = { name: item.name, type: item.type }
      if (!isNewLine) data.id = item.id

      if (isNewLine) {
        postProductConfigurationRequest(this.urlSuffix, data).then(response => {
          const createdLine = {
            id: response.data.id,
            index: item.index,
            name: item.name,
            initialType: JSON.parse(JSON.stringify(item.type)),
            type: item.type,
            initialName: JSON.parse(JSON.stringify(item.name)),
          }
          this.values.push(createdLine)
          this.newLine()
        })
        this.removeDirtyStatus(item.index)
        this.removeLoadingStatus(item.index)
      } else {
        patchProductConfigurationRequest(this.urlSuffix, data).then(() => {
          this.removeDirtyStatus(item.index)
          this.removeLoadingStatus(item.index)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group-prepend span fieldset {
  margin-bottom: 0;
}
.input-group-append {
  display: unset;
}
#ProductPackingType {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
</style>
