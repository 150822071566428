<template>
  <b-card no-body>
    <b-card-body v-if="displayTitle">
      <b-card-title v-if="editableName">
        <b-row>
          <b-col lg="6" xl="5">
            <app-form-text-input-itn
              id="workroleName"
              v-model="workrole"
              :label="$t('user.workrole')"
              :languages="$store.state.auth.activelang"
              form-name="workrole-name"
            />
          </b-col>
        </b-row>
      </b-card-title>

      <b-card-title v-else>
        <h4 class="text-center">{{ userData.workrole }}</h4>
      </b-card-title>
    </b-card-body>

    <app-data-table
      :busy="!userData.rights"
      :fields="fields"
      :hover="false"
      :items="localRights"
      :small="$store.getters['app/mdAndDown']"
      all-items
      sticky-header
      striped
      table-class="mb-0 rights-table-content"
      table-name="user-rights"
    >
      <template v-for="field in fields" :slot="`cell(${field.key})`" slot-scope="{ item }">
        <div :key="field.key" class="d-flex justify-content-center">
          <b-form-checkbox
            :id="toUpper(`${item.name}_${field.key}`)"
            :checked="item[field.key]"
            :disabled="!editableRights || !grantableRights.includes(toUpper(`${item.name}_${field.key}`))"
            style="cursor: pointer"
            @change="updateRights(toUpper(`${item.name}_${field.key}`), $event)"
          />
        </div>
      </template>

      <template #cell(name)="{ item }">
        {{ $t(`user.right.features.${item.name}`) }}
      </template>
    </app-data-table>
  </b-card>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import { snakeCase, toUpper, uniq } from 'lodash'

import AppDataTable from '@/components/AppDataTable.vue'
import AppSelect from '@/components/AppSelect.vue'
import AppFormTextInputItn from '@/components/AppFormTextInputItn.vue'

export default defineComponent({
  name: 'OperatorRightsTable',

  components: {
    AppDataTable,
    AppSelect,
    AppFormTextInputItn,
  },

  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    grantableRights: {
      type: Array,
      default: () => [],
    },
    workrole: {
      type: [Object],
      default: null,
    },
    userData: {
      type: Object,
      default: () => ({ id: null }),
    },
    editableName: {
      type: Boolean,
      default: true,
    },
    editableRights: {
      type: Boolean,
      default: true,
    },
    displayTitle: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { $i18n, $can, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const fields = ref([{ key: 'name', label: $i18n.t('user.right.name'), tdClass: 'w-100' }])
    const localRights = ref([])
    const userLang = ref(localStorage.getItem('lang'))

    watch([() => props.userData, () => props.grantableRights], () => {
      // Future pattern: { the_feature: { action: <bool>, ... }, ... }
      const rights = {}

      // Merge his and my rights to display all his rights and all my possibilities to update its.
      // If userData is me or operator, so `props.grantableRights === props.userData.rights` (or include), and merge is useless.
      const mergeRights = props.isMe || $can('OPERATOR_PARAMETER_VIEW') ? props.grantableRights : uniq(props.grantableRights.concat(props.userData.rights))
      mergeRights.forEach(right => {
        // Pattern: THE_FEATURE_ACTION => `the_feature` and `action`. Useful also for translations.
        const feature = snakeCase(right.match(/(.*)_/)[1])
        const action = snakeCase(right.match(/.*_(.*)/)[1])

        // Get all differents types actions to fill `fields` prop
        if (!fields.value.find(field => field.key === action)) {
          fields.value.push({
            key: action,
            label: $i18n.t(`user.right.${action}`),
          })
        }

        // If `userData` is me, it's useless to check the includes()
        const isGranted = props.isMe || props.userData.rights.includes(right)

        if (rights[feature]) {
          rights[feature][action] = isGranted
        } else {
          rights[feature] = { name: feature, [action]: isGranted }
        }
      })

      // The `fields` will be sorted along `orderActions`
      const orderActions = ['view', 'add', 'edit', 'delete']
      fields.value = fields.value.sort((firstAction, secondAction) => {
        const firstActionIndex = orderActions.findIndex(action => action === firstAction.key)

        // If `firstAction.key` is not include in `orderActions`, keep his position
        if (firstActionIndex === -1) return 0
        const secondActionIndex = orderActions.findIndex(action => action === secondAction.key)
        return firstActionIndex - secondActionIndex
      })

      // `rights` becomes `[{ the_feature: { action: <bool>, ...} }, ...]` - valid format to b-table.
      localRights.value = Object.values(rights)

      // TODO: immediate true is not a good idea, because for FO, the code runs twice
    }, { deep: true, immediate: true })

    let rightsToAdd = _cloneDeep(props.userData.rightsToAdd)
    let rightsToDelete = _cloneDeep(props.userData.rightsToDelete)

    // Fill `rightsToAdd` or `rightsToDelete` about update rights and his old rights
    const updateRights = (right, isAdd) => {
      if (isAdd) {
        // Si c'est un ajoute et qu'il est dans la liste des "à supprimer", alors on le retire et on l'ajoute (même si existe déjà)
        if (rightsToDelete.includes(right)) {
          rightsToDelete = rightsToDelete.filter(rightToDelete => rightToDelete !== right)
        }

        rightsToAdd.push(right)
      } else {
        // Si c'est une suppression et qu'il est dans la liste des "à ajouter", alors on le retire des ajouts et on supprime
        if (rightsToAdd.includes(right)) {
          rightsToAdd = rightsToAdd.filter(rightToAdd => rightToAdd !== right)
        }

        rightsToDelete.push(right)
      }

      $emit('update:rights', { rightsToAdd, rightsToDelete })
    }

    return {
      userLang,
      fields,
      localRights,
      updateRights,
      toUpper,
      rightsToAdd,
      rightsToDelete,
    }
  },
})
</script>

<style>
.rights-table-content {
  max-height: 70vh;
}
</style>
